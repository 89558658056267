﻿.c-skip {

	@include defer {

		&:focus {
			@include show();
			color: color(black);
			background: color(white);
			position: absolute;
			top: 10px;
			left: 10px;
			padding: 5px;
			text-decoration: none;
		}
	}
}
